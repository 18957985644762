import { User } from '~/api/account/accountApiTypes';

export enum ProviderEnum {
  vkontakte = 'vkontakte',
  yandex = 'yandex',
  mailru = 'mailru',
  apple = 'apple',
  google = 'google',
}

export type RegisterRequestParams = {
  email: string;
  name: string;
  password: string;
  gRecaptchaResponse: string;
};
export type LoginRequestParams = {
  email: string;
  password: string;
  rememberMe: number;
};

export interface LoginRequestRejectValue {
  email?: string[];
  password?: string[];
  rememberMe?: string[];
}

export type ForgotRequestParams = {
  email: string;
};
export type ResetPasswordRequestParams = {
  token: string;
  password: string;
};

export interface ResetPasswordErrorValid {
  token: string[];
  password: string[];
}

export type ConfirmRegistrationRequestParams = {
  token: string;
};
export type ProviderLoginParams = {
  provider: ProviderEnum;
  state: string;
};
export type ProviderLoginResponse = {
  redirectUrl: string;
};
export type ConfirmProviderLogin = {
  provider: ProviderEnum;
  code: string;
  user?: any;
};

export interface LoginResponse {
  token: string;
  ttl: number;
  message: string;
  uuid: string;
  data: User;
}

export type ConfirmProviderResponse = {
  message: string;
  token: string;
  uuid: string;
  ttl: number;
  isNewUser?: boolean;
  data: User;
};

export interface EmailEvailabilityResponse {
  available: boolean;
}

export interface QuickRegisterResponse extends LoginResponse {
  password?: string;
}

export type QuickRegisterParams = {
  email: string;
};

export interface WithToken {
  token?: string;
}
