import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { User } from '~/api/account/accountApiTypes';
import { BookIdType } from '~/api/book/bookApiTypes';
import { financeApi } from '~/api/finance/financeApi';
import {
  Methods,
  PayEntitiesEnum,
  RegisterPayBookParams,
  TransactionInfo,
  TransactionResult,
} from '~/api/finance/financeApiTypes';
import { readerActions } from '~/atomic/page/book-reader/reader.slice';
import { financePageSliceName } from '~/atomic/page/finance/financePage.slice';
import { authActions } from '~/feature/authorization/auth.slice';
import { getUserEmailInLocalStorage } from '~/feature/authorization/SaveUserLoginModal/saveUserEmailInLocalStorage';
import { getBookPageData } from '~/feature/book/book.data';
import { bookPageSelector, currentGraphqlBookSelector } from '~/feature/book/book.selector';
import { buyAbonnementActions } from '~/feature/book/buyAbonnement/buyAbonnement.slice';
import { buyBookSelector } from '~/feature/book/buyBook/buyBook.selector';
import { buyBookActions, buyBookSliceName } from '~/feature/book/buyBook/buyBook.slice';
import { paymentResultActions } from '~/feature/book/paymentResult/paymentResult.slice';
import {
  buyBookClickedChapterIdName,
  buyBookPayEntityName, choosePaySelector,
} from '~/feature/finance/BuyingSteps/ChoosePay/choosePay.selector';
import { choosePayActions } from '~/feature/finance/BuyingSteps/ChoosePay/choosePay.slice';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { getQueriesFromUrl } from '~/feature/utils/getQueriesFromUrl/getQueriesFromUrl';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { environments } from '~/lib/const';
import { createAppThunk, RootState } from '~/store';

export const USER_CURRENT_TRANSACTION_KEY = 'user_current_transaction';

export const registerBuyBook = createAsyncThunk<
void,
RegisterPayBookParams, {
  rejectValue: { error: string }; state: RootState
}>(
  `${financePageSliceName}/registerPayBook`,
  async (
    data,
    thunkAPI,
  ) => {
    const successTransactionHandler = () => {
      deleteUserTransactionFromLocalStorage();
      thunkAPI.dispatch(buyBookActions.setIsOpenChoosePayModal(false));
      thunkAPI.dispatch(paymentResultActions.openModal('buyBookSuccess'));
    };

    const failedTransactionHandler = () => {
      deleteUserTransactionFromLocalStorage();
      thunkAPI.dispatch(buyBookActions.setIsOpenChoosePayModal(false));
      thunkAPI.dispatch(paymentResultActions.openModal('buyBookReject'));
    };

    try {
      const returnUrl = qs.parseUrl(data.returnUrl);
      returnUrl.query.transactionId = 'TIDSET';

      const result = await financeApi.registerPayBook({
        ...data,
        returnUrl: qs.stringifyUrl(returnUrl),
      });

      if (!(result instanceof Error)) {
        ym('reachGoal', 'user-start-pay-book');
        if (result && result.data.confirmationUrl) {
          saveUserTransactionInLocalStorage({
            transactionId: result.data.transactionId,
            methodPayment: data.methodPayment,
            payEntity: PayEntitiesEnum.book,
            data: { id: data.bookId },
          });

          window.location.href = result.data.confirmationUrl;
          return;
        }

        if (data.methodPayment !== Methods.balance) {
          thunkAPI.dispatch(checkUserTransaction({
            transactionId: result.data.transactionId,
            methodPayment: data.methodPayment,
            payEntity: PayEntitiesEnum.book,
            successTransactionHandler,
            failedTransactionHandler,
          }));
        } else {
          successTransactionHandler();
        }
      }
    } catch (error) {
      failedTransactionHandler();
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const saveUserTransactionInLocalStorage = (info: TransactionInfo) => {
  if (environments.isClient) {
    localStorage.setItem(USER_CURRENT_TRANSACTION_KEY, JSON.stringify(info));
  }
};

export const getUserTransactionFromLocalStorage = <T>(): TransactionInfo<T> | undefined => {
  if (environments.isClient) {
    return JSON.parse(localStorage.getItem(USER_CURRENT_TRANSACTION_KEY)) as TransactionInfo<T>;
  }
};

export const deleteUserTransactionFromLocalStorage = () => {
  if (environments.isClient) {
    localStorage.removeItem(USER_CURRENT_TRANSACTION_KEY);
  }
};

export type CheckUserTransactionHandlersType = {
  successTransactionHandler: (paymentInfo: TransactionInfo & {
    transactionResult: TransactionResult
  }) => void;
  failedTransactionHandler: (paymentInfo: TransactionInfo) => void;
};

export const checkUserTransaction = createAppThunk<
void,
TransactionInfo & CheckUserTransactionHandlersType>(
  `${financePageSliceName}/checkUserTransaction`,
  async ({
    transactionId,
    methodPayment,
    payEntity,
    failedTransactionHandler,
    successTransactionHandler,
    data,
  }, thunkAPI) => {
    try {
      thunkAPI.dispatch(paymentResultActions.setIsOpenTransactionLoader(true));
      let transactionResult: TransactionResult;

      if (methodPayment === Methods.Tinkoff) {
        const result = await financeApi.transactionTinkoffResult({ transactionId: Number(transactionId) });

        transactionResult = result.purchase;
      } else {
        const result = await financeApi.transactionResult({ transactionId: Number(transactionId) });

        transactionResult = result.purchase;
      }

      if (transactionResult) {
        thunkAPI.dispatch(paymentResultActions.changeTransactionResult(transactionResult));
        thunkAPI.dispatch(paymentResultActions.changeErrorMessage(''));
        successTransactionHandler({
          transactionId,
          payEntity,
          methodPayment,
          transactionResult,
          data,
        });
      } else {
        failedTransactionHandler({
          transactionId,
          payEntity,
          methodPayment,
          data,
        });
      }
    } catch (e) {
      if (e?.message) {
        thunkAPI.dispatch(paymentResultActions.changeErrorMessage(e.message));
      }
      failedTransactionHandler({
        transactionId,
        payEntity,
        methodPayment,
        data,
      });
    } finally {
      thunkAPI.dispatch(paymentResultActions.setIsOpenTransactionLoader(false));
    }
  },
);

export const checkUserTransactionFromLocalStorage = createAppThunk<void, CheckUserTransactionHandlersType>(
  `${financePageSliceName}/checkUserTransactionFromLocalStorage`,
  async (handlers, thunkAPI) => {
    const userTransaction = getUserTransactionFromLocalStorage<{ id?: BookIdType }>();
    const isReaderPage = Router.pathname.startsWith('/reader');
    const isBookPage = Router.pathname.startsWith('/book');
    const isPayBook = userTransaction.payEntity === PayEntitiesEnum.book;
    const isHaveSaveBookId = userTransaction?.data && 'id' in userTransaction.data;

    // Проверка нужна, чтобы убедится, что мы проверяем транзакцию для книги, которую мы сейчас оплатили
    // В зависимости от того, на какой странице произошла оплата, необходимо доставать id книги
    if (isPayBook && (isBookPage || isReaderPage)) {
      let bookId;

      if (isBookPage) {
        const book = bookPageSelector(thunkAPI.getState());
        bookId = book?.id;
      }

      if (isReaderPage) {
        const book = currentGraphqlBookSelector(thunkAPI.getState());
        bookId = book?.id;
      }

      if (isHaveSaveBookId && bookId !== Number(userTransaction.data.id)) {
        return;
      }
    }

    if (userTransaction) {
      await thunkAPI.dispatch(checkUserTransaction({ ...userTransaction, ...handlers }));
      deleteUserTransactionFromLocalStorage();
    }
  },
);

export const handleClickBuyBookButton = createAsyncThunk<
void,
void,
{ rejectValue: void, state: RootState }
>(`${buyBookSliceName}/openBuyBookModal`, async (data, { dispatch, getState }) => {
  const isLoggedIn = isLoggedInSelector(getState());
  ym('reachGoal', 'buy-book-button-click');
  dispatch(buyBookActions.hideBuyModal());
  if (isLoggedIn) {
    dispatch(buyBookActions.setIsOpenChoosePayModal(true));
  } else {
    dispatch(openLoginModalWithChoosePay({ payEntity: PayEntitiesEnum.book }));
  }
});

export const openBuyBookModal = createAsyncThunk<
void,
void,
{ rejectValue: void, state: RootState }
>(`${buyBookSliceName}/openBuyBookModal`, async (data, thunkAPI) => {
  thunkAPI.dispatch(buyBookActions.showBuyModal());
});

export const checkOpenBuyBookModalFromUrl = createAsyncThunk<
void,
{ afterLogin:(user: User) => Promise<void> },
{ rejectValue: void, state: RootState }
>(`${buyBookSliceName}/checkOpenBuyBookModalFromUrl`, async ({ afterLogin }, thunkAPI) => {
    const {
      buyBook, payBookClickedChapterId, payBookPayEntity,
    } = await getQueriesFromUrl({ searchedQueries: [buyBookSliceName, buyBookPayEntityName, buyBookClickedChapterIdName] });

    if (
      environments.isClient
    && buyBook
    ) {
      const { user } = userSelector(thunkAPI.getState());

      if (payBookPayEntity) thunkAPI.dispatch(choosePayActions.changePayEntity(payBookPayEntity as PayEntitiesEnum));
      if (payBookClickedChapterId && Number.isInteger(payBookClickedChapterId)) thunkAPI.dispatch(readerActions.changeClickedChapterId(Number(payBookClickedChapterId)));
      if (user) {
        afterLogin(user as User);
      } else {
        thunkAPI.dispatch(openBuyBookModal());
      }
    }
  });

export const buyBook = createAsyncThunk<
void, { bookId: BookIdType }, { state: RootState; }
>(
  `${buyBookSliceName}/buyBook`,
  async (data, thunkAPI) => {
    const { user: { email: userEmail } } = userSelector(thunkAPI.getState());
    const { activeMethod, isSaveBankCard } = thunkAPI.getState().choosePay;
    const { email: emailFromStorage } = buyBookSelector(thunkAPI.getState());
    const bankCardId = 'card' in activeMethod ? activeMethod.card?.id : undefined;
    const email = userEmail ? undefined : emailFromStorage;

    await thunkAPI.dispatch(registerBuyBook({
      bookId: data.bookId,
      methodPayment: activeMethod.type,
      returnUrl: window?.location?.href,
      email,
      bankCardId,
      saveBankCard: isSaveBankCard,
    }));
  },
);

export const openLoginModalWithChoosePay = createAsyncThunk<
void, { payEntity: PayEntitiesEnum }, { state: RootState; }
>(
  `${buyBookSliceName}/openLoginModalWithChoosePayBook`,
  async ({ payEntity }, thunkAPI) => {
    const userEmailFromStorage = unwrapResult(await thunkAPI.dispatch(getUserEmailInLocalStorage()));

    if (userEmailFromStorage) {
      thunkAPI.dispatch(authActions.changeEmail(userEmailFromStorage));
    }

    thunkAPI.dispatch(choosePayActions.changePayEntity(payEntity));
    thunkAPI.dispatch(choosePayActions.setIsOpenQuickAuthModalWithChoosePayBook(true));
  },
);

export const buyBookFree = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }, state: RootState }
>(
  `${buyBookSliceName}/buyBookFree`,
  async (data, thunkAPI) => {
    const { currentGraphqlBook } = thunkAPI.getState().book;
    try {
      await financeApi.buyBookFree({ bookId: currentGraphqlBook?.id });

      await thunkAPI.dispatch(getBookPageData({ input: { id: currentGraphqlBook?.id } }));
      thunkAPI.dispatch(paymentResultActions.openModal('buyBookFreeSuccess'));
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const afterLoginHandler = createAsyncThunk<
void,
{
  bookBought: boolean,
  bookAddedToAbonnement: boolean,
  haveUserAbonnement: boolean,
  bookId: number
},
{ rejectValue: { error: string }, state: RootState }
>(
  `${buyBookSliceName}/afterLoginHandler`,
  async ({
    bookBought, bookAddedToAbonnement, haveUserAbonnement, bookId,
  }, { dispatch, getState }) => {
    const { payEntity } = choosePaySelector(getState());

    if (bookBought && payEntity === PayEntitiesEnum.book) {
      dispatch(buyBookActions.setOpenAlreadyBoughtModal(true));
    } else if (
      payEntity === PayEntitiesEnum.Abonnement
      && bookAddedToAbonnement && haveUserAbonnement
    ) {
      dispatch(buyBookActions.setOpenAlreadyReadBookByAbonnementModal(true));
    } else if (bookBought) {
      dispatch(buyBookActions.setOpenAlreadyBoughtModal(true));
    } else if (bookAddedToAbonnement && haveUserAbonnement) {
      dispatch(buyBookActions.setOpenAlreadyReadBookByAbonnementModal(true));
    } else if (payEntity === PayEntitiesEnum.Promocode) {
      dispatch(openBuyBookModal());
    } else if (payEntity === PayEntitiesEnum.Abonnement) {
      dispatch(buyAbonnementActions.setIsOpenChoosePayModal(true));
    } else {
      dispatch(buyBookActions.setIsOpenChoosePayModal(true));
    }

    dispatch(choosePayActions.changePayEntity(''));
  },
);
