import { createSelector } from '@reduxjs/toolkit';

import { readerSelector } from '~/atomic/page/book-reader/reader.selectors';
import { buyBookSliceName } from '~/feature/book/buyBook/buyBook.slice';
import { RootState } from '~/store';

export const choosePaySelector = (state: RootState) => state.choosePay;

export const buyBookPayEntityName = 'payBookPayEntity';
export const buyBookClickedChapterIdName = 'payBookClickedChapterId';

export const buyBookQueriesSelector = createSelector(
  choosePaySelector,
  readerSelector,
  ({ payEntity }, { clickedChapterId }) => {
    const queries = [{ name: buyBookSliceName, value: 'true' }];
    if (payEntity) {
      queries.push({ name: buyBookPayEntityName, value: payEntity });
    }

    if (clickedChapterId) {
      queries.push({ name: buyBookClickedChapterIdName, value: clickedChapterId.toString() });
    }

    return queries;
  },
);
