import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const authorization = 'authorization';

export type ModalNames = 'login' | 'register' | 'recovery' | 'saveUserLogin' | '';

export const authorizationModalSlice = createSlice({
  name: `${authorization}/modal`,
  initialState: { openedModal: '' as ModalNames, successRecovery: false },
  reducers: {
    open: (state, action: PayloadAction<ModalNames>) => {
      state.openedModal = action.payload;
    },
    setSuccessRecovery: (state, action: PayloadAction<boolean>) => {
      state.successRecovery = action.payload;
    },
  },
});

export type AuthorizationModalState = ReturnType<typeof authorizationModalSlice.reducer>;

export const authorizationModalReducer = authorizationModalSlice.reducer;

export const { open: openModal, ...authModalActions } = authorizationModalSlice.actions;
