import React from 'react';
import styled from 'styled-components';

import { StarsIcon } from '~/atomic/atom/icon/StartsIcon';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { NavItem } from '~/atomic/organism/NavMenu';
import { isHaveUserAbonnementSelector, userSelector } from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

export const ReaderNavigationAbonnement = () => {
  const { user } = useAppSelector(userSelector);
  const isHaveUserAbonnement = useAppSelector(isHaveUserAbonnementSelector);

  if (!isHaveUserAbonnement) {
    return (
      <NavItem
        href={pagesConfigStore.bookSubscriptions.url}
        shallow
      >
        <SCAbonnementTextWrapper>
          <SCStarsIcon />
          <SCText>
            Абонемент
          </SCText>
          <SCEmpty>
            нет
          </SCEmpty>
        </SCAbonnementTextWrapper>
      </NavItem>
    );
  }

  return (
    <NavItem
      arrowProps={{ style: { color: '#4B96FB' } }}
      href={pagesConfigStore.bookSubscriptions.url}
      shallow
    >
      <SCActivateAbonnementTextWrapper>
        <SCStarsIcon />
        <SCText>
          Абонемент подключён
        </SCText>
      </SCActivateAbonnementTextWrapper>
    </NavItem>
  );
};

const SCStarsIcon = styled(StarsIcon)`
  font-size: 20px;
  margin-right: 20px;
`;

const SCText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SCEmpty = styled.span`
  display: inline-flex;
  margin-left: auto;
`;

const SCActivateAbonnementTextWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: #4B96FB;;
`;

const SCAbonnementTextWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: var(--black-color);
  width: 100%;
  padding-right: 20px;
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--primary-hover-color);
  }
`;
