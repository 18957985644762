import { RegistrationRequest } from '~/api/account/userApiTypes';
import {
  Author, BookIdType, CompleteStatusEnum, CoverImage,
} from '~/api/book/bookApiTypes';

export enum PayEntitiesEnum {
  book = 'book',
  AudioBook = 'audioBook',
  reward = 'reward',
  rewardInComment = 'rewardInComment',
  AssetBalance = 'assetBalance',
  AdAccountReplenishment = 'adAccountReplenishment',
  Abonnement = 'abonnement',
  Promocode = 'promocode',
}

export interface BankCard {
  id: number;
  number: string;
  expiresDate: string;
}

export enum YandexKassaErrors {
  card_expired = 'Срок дейсвия карты истек',
  general_decline = 'Причина неизвеста, обратитесь в поддержку',
  insufficient_funds = `Не хватает денег для оплаты.
  Вам следует пополнить баланс или использовать другое платежное средство`,
  expired_on_confirmation = 'Истек срок платежа, повторите платеж',
  call_issuer = `Оплата данным платежным средством отклонена по неизвестным причинам.
  Вам следует обратиться в организацию, выпустившую платежное средство`,
  fraud_suspected = `Платеж заблокирован из-за подозрения в мошенничестве.
  При новой попытке оплаты следует использовать другое платежное средство`,
  issuer_unavailable = `Организация, выпустившая платежное средство, недоступна. 
  При новой попытке оплаты следует использовать другое
  платежное средство или повторить оплату позже`,
  payment_method_restricted = `Запрещены операции данным платежным средством
  (например, карта заблокирована из-за утери, кошелек — из-за взлома мошенниками).
  Вам следует обратиться в организацию, выпустившую платежное средство`,
}

export interface RegisterTransaction {
  returnUrl?: string;
  methodPayment: Methods;
  bankCardId?: number;
  saveBankCard?: boolean;
}

export type TransactionInfo<D = {}> = {
  methodPayment: Methods;
  transactionId: number;
  payEntity: PayEntitiesEnum;
  data?: D;
};

export interface RegisterPayBookParams extends RegisterTransaction {
  bookId: BookIdType;
  email?: string;
}

export interface BuyBookFreeParams {
  bookId: BookIdType;
}

export interface RegisterRewardBookParams extends RegisterPayBookParams {
  amount: number;
  comment?: string;
}

export interface AssetBalanceParams extends RegisterTransaction {
  amount?: number;
}

export interface TransactionResultParams {
  transactionId: number;
}

export type TransactionResult = {
  price: number;
  isVMD: boolean;
  boughtWithTimerDiscount: boolean;
  incomeByPurchaseVmdGoal: number;
  incomeByPurchaseTotalGoal: number;
  incomeByPurchase: number;
};

export interface RegisterTransactionResult {
  data?: {
    confirmationUrl: string;
    billId: number;
    transactionId: number;
  }
}

export enum WithdrawalMethodTypeEnum {
  Card = 'card',
  Bill = 'bill',
}

export interface WithdrawalMethod {
  uuid: string;
  type: WithdrawalMethodTypeEnum;
  title: string;
}

export interface AgencyReport {
  id: string;
  startDate: string;
  endDate: string;
  name: string;
  type: string;
}

export interface GetBalanceResult {
  balance: number;
  blocked: number;
  withdraw: number;
  commissionService: number;
  accessAmountWithdraw: number;
  isReceivingPayments: boolean;
  isWithdraw: boolean;
  haveCardTinkoff: boolean;
  commissionsForCurrentYear: number;
  attached: WithdrawalMethod;
  paymentSettings: {
    auto: boolean;
  },
  blockedByBooks: [
    {
      id: number;
      name: string;
      slug: string;
      amount: number;
    },
  ],
  request: RegistrationRequest;
}

export interface PurchaseBook {
  id: number;
  name: string;
  slug: string;
  coverUrl: string;
  coverImages: CoverImage[];
  authors: Author[],
  price: number;
  discount: {
    price: number;
    percent: number;
  },
  statusComplete: CompleteStatusEnum
}

export interface Purchase {
  billId: number;
  amount: number;
  createdAt: string;
  books: PurchaseBook[];
  audiobooks: PurchaseBook[];
}

export interface CreateRewardParams {
  authorId: number;
  amount: number;
  message?: string;
}

export enum Methods {
  yoo_money = 'yoo_money',
  bankCard = 'bank_card',
  savedBankCard = 'saved_bank_card',
  balance = 'balance',
  Qiwi = 'qiwi',
  WebMoney = 'webmoney',
  SBP = 'yookassa_sbp',
  Tinkoff = 'tinkoff',
}

export interface SaveBankCardMethod {
  type: Methods.savedBankCard;
  name?: string;
  card: BankCard;
}

export interface BalanceMethod {
  type: Methods.balance;
  name?: string;
  sum?: number;
}

export interface QiwiMethod {
  type: Methods.Qiwi;
  name?: string;
}

export interface WebMoneyMethod {
  type: Methods.WebMoney;
  name?: string;
}

export interface BankCardMethod {
  type: Methods.bankCard;
  name?: string;
}

export interface YandexMoneyMethod {
  type: Methods.yoo_money;
  name?: string;
}

export interface TinkoffMethod {
  type: Methods.Tinkoff;
  name?: string;
}

export interface SBPMethod {
  type: Methods.SBP;
  name?: string;
}

export type MethodPayment = SaveBankCardMethod
| BalanceMethod
| BankCardMethod
| YandexMoneyMethod
| QiwiMethod
| WebMoneyMethod
| TinkoffMethod
| SBPMethod;

export type GetMethodResult = BalanceMethod
| BankCardMethod
| YandexMoneyMethod
| QiwiMethod
| WebMoneyMethod
| TinkoffMethod
| {
  type: Methods.savedBankCard;
  name?: string;
  card?: BankCard[];
};

export interface Commissions {
  commissionSaleOfBook: number;
}

export interface UnLinkBankCardParams {
  bankCardId: string | number;
}

export interface GenerateReferralLinkParams {
  returnUrl: string;
}

export interface ReferralUrlVisitedParams {
  visitedUrl: string;
}

export interface GenerateReferralLinkResult {
  url: string;
}

export interface SetPartnerParams {
  visitedUrl: string;
}

export interface RemoveConnectedPayoutMethodProps {
  uuid: number | string;
}

export interface ConnecteBillPayoutMethodParams {
  number: string;
}

export interface WithdrawMoneyParams {
  attachedPaymentMethodUuid: string;
  amount: number;
}

export interface ChangeAutoPaymentStatusParams {
  auto: boolean;
  autoAmount?: number | string;
}

export interface ExportAgencyReportProps {
  id: string | number;
  type: string;
}

export enum PaymentRequestMethodEnum {
  Bill = 'bill',
  Card = 'card',
}

export enum PaymentRequestStatusEnum {
  Processing = 'processing',
  Approved = 'approved',
  Declined = 'declined',
}

export interface PaymentRequest {
  id: number;
  amount: number;
  title: string;
  method: PaymentRequestMethodEnum;
  status: PaymentRequestStatusEnum;
  cause: string;
  auto: boolean;
  createdAt: string;
}

export interface PaymentRequestsParams {
  page: number;
  perPage: number;
}

export interface RegisterPayBookWithPromocode {
  bookId: BookIdType;
  promocode: string;
}

export interface RegisterPayBookWithPromocodeResult {
  bookBought: boolean;
  appliedDiscountPercentage: number;
  appliedPrice: number;
}

export interface DownloadAgreementExclusiveBookSaleProps {
  bookId: BookIdType;
}
