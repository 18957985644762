import {
  ConfirmProviderLogin,
  ConfirmProviderResponse,
  ConfirmRegistrationRequestParams, EmailEvailabilityResponse,
  ForgotRequestParams,
  LoginRequestParams,
  LoginResponse,
  ProviderEnum,
  ProviderLoginParams,
  ProviderLoginResponse,
  QuickRegisterParams,
  QuickRegisterResponse,
  RegisterRequestParams,
  ResetPasswordErrorValid,
  ResetPasswordRequestParams,
} from '~/api/auth/authApiTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';
import { getDeviceSessionUid } from '~/feature/utils/deviceSessionUid';

import {
  baseFrontServerUrl,
  provider,
} from '../provider/provider.config';

const namespace = Symbol('namespace');

export const authApi = {
  [namespace]: '/v1/auth',
  async register(data: RegisterRequestParams) {
    const deviceSessionUid = getDeviceSessionUid();
    try {
      const response = await provider.post<
      LoginResponse | RejectedRequest<RegisterRequestParams>
      >(
        `${this[namespace]}/register`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async login(data: LoginRequestParams) {
    try {
      const deviceSessionUid = getDeviceSessionUid();

      const response = await provider.post<
      LoginResponse | RejectedRequest<LoginRequestParams>
      >(
        `${this[namespace]}/login`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async logout() {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/logout`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async forgot(data: ForgotRequestParams) {
    try {
      await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/forgot`, data);
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async resetPassword(data: ResetPasswordRequestParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<ResetPasswordErrorValid>>
      >(`${this[namespace]}/resetPassword`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async confirmRegistration(data: ConfirmRegistrationRequestParams) {
    try {
      const deviceSessionUid = getDeviceSessionUid();
      await provider.post<
      ApiResponse<LoginResponse, RejectedRequest<void>>
      >(
        `${this[namespace]}/confirm`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async loginProvider(data: ProviderLoginParams, dev = false) {
    const headers = dev ? { devhost: baseFrontServerUrl } : undefined;
    const state = data.provider === ProviderEnum.vkontakte ? encodeURIComponent(data.state) : data.state;

    try {
      const response = await provider.get<
      ProviderLoginResponse | RejectedRequest<void>
      >(
        `${this[namespace]}/login/${data.provider}`,
        {
          params: { state },
          headers,
        },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async loginProviderCallback(data: ConfirmProviderLogin) {
    try {
      const deviceSessionUid = getDeviceSessionUid();

      const response = await provider.post<
      ConfirmProviderResponse | RejectedRequest<void>
      >(
        `${this[namespace]}/login/${data.provider}/callback`,
        { code: data.code, user: data?.user },
        { headers: { 'device-session-uid': deviceSessionUid } },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async quickRegister(data: QuickRegisterParams) {
    try {
      const deviceSessionUid = getDeviceSessionUid();

      const response = await provider.post<
      QuickRegisterResponse | RejectedRequest<{ email: string }>
      >(
        `${this[namespace]}/quickRegistration`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async emailAvailability(email: string) {
    try {
      const response = await provider.get<{
        data: EmailEvailabilityResponse
      }>(`${this[namespace]}/email-availability`, { params: { email } });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
