import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { User } from '~/api/account/accountApiTypes';
import { ProviderEnum } from '~/api/auth/authApiTypes';
import { Button, SCButton } from '~/atomic/atom/Button';
import { AppleIcon } from '~/atomic/atom/icon/AppleIcon';
import { GoogleIcon } from '~/atomic/atom/icon/GoogleIcon';
import { MailIcon } from '~/atomic/atom/icon/MailIcon';
import { VkIcon } from '~/atomic/atom/icon/VkIcon';
import { YandexIcon } from '~/atomic/atom/icon/YandexIcon';
import { Input } from '~/atomic/atom/Input';
import { Checkbox } from '~/atomic/molecula/Checkbox';
import { Field } from '~/atomic/molecula/Field';
import {
  TextBoldStyle, TextRegStyle, TextSmallStyle,
} from '~/atomic/Typography';
import { getProviderLoginURL, login } from '~/feature/authorization/auth.data';
import { authSelector } from '~/feature/authorization/auth.selector';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { useAppDispatch, useAppSelector } from '~/store';

interface LoginFormProps {
  onRegisterClick?: () => void;
  onRecoveryClick?: () => void;
  afterLogin?: (user: User) => void;
  queries?: { name: string; value: string; }[];
  initialState?: {
    email?: string,
    password?: string,
  }
}

export const LoginForm: FC<LoginFormProps> = ({
  afterLogin, queries, onRegisterClick, onRecoveryClick, initialState = {},
}) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(authSelector);

  const handleSocialNetworkIconClick = async (provider: ProviderEnum) => {
    await dispatch(getProviderLoginURL({ provider, queries }));
  };

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
      ...initialState,
    },
    onSubmit: async ({
      email, password, rememberMe,
    }, formikHelpers) => {
      ym('reachGoal', 'auth-button-click');
      const result = await dispatch(login({
        email, password, rememberMe: Number(rememberMe), afterLogin,
      }));

      if (login.rejected.match(result) && result.payload?.errors) {
        formikHelpers.setErrors({
          email: result.payload?.errors?.email?.[0],
          password: result.payload?.errors?.password?.[0],
          rememberMe: result.payload?.errors?.rememberMe?.[0],
        });
      }
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup
        .string()
        .required()
        .email('Введите корректный E-mail адрес'),
      password: Yup.string().required(),
    }),
  });

  useEffect(() => {
    return () => {
      loginForm.resetForm();
    };
  }, []);

  return (
    <SCLoginForm
      onSubmit={loginForm.handleSubmit}
    >
      <SCRowSocials>
        <SCVkIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.vkontakte)}
        >
          <SCVKIcon />
        </SCVkIconWrapper>
        <SCGoogleIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.google)}
        >
          <SCGoogleIcon />
        </SCGoogleIconWrapper>
        <SCMailIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.mailru)}
        >
          <SCMailIcon />
        </SCMailIconWrapper>
        <SCYandexIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.yandex)}
        >
          <SCYandexIcon />
        </SCYandexIconWrapper>
        <SCAppleIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.apple)}
        >
          <SCAppleIcon />
        </SCAppleIconWrapper>
      </SCRowSocials>
      <SCOr>
        или
      </SCOr>

      <SCFieldsWrap>
        <Field
          error={loginForm.touched.email && loginForm.errors.email}
        >
          <SCInput
            placeholder="E-mail"
            name="email"
            onChange={(e) => {
              loginForm.setFieldValue('email', e.target.value.trim());
            }}
            onBlur={loginForm.handleBlur}
            value={loginForm.values.email}
          />
        </Field>
        <Field
          error={loginForm.touched.password && loginForm.errors.password}
        >
          <SCInput
            name="password"
            type="password"
            placeholder="Пароль"
            onChange={(e) => {
              loginForm.setFieldValue('password', e.target.value.trim());
            }}
            onBlur={loginForm.handleBlur}
            value={loginForm.values.password}
          />
        </Field>
      </SCFieldsWrap>

      <SCRowRememberMe>
        <SCCheckbox
          name="rememberMe"
          onChange={(e) => {
            loginForm.setFieldValue('rememberMe', e.target.checked);
          }}
          checked={loginForm.values.rememberMe}
          title="Запомнить меня"
        />

        <SCLink onClick={onRecoveryClick}>
          Забыли пароль?
        </SCLink>
      </SCRowRememberMe>

      <SCButtonWrapp>
        <Button
          type="primary"
          htmlType="submit"
          block
          isLoading={loading.login === true}
        >
          Войти
        </Button>
      </SCButtonWrapp>

      <SCNoAccountLabel>
        Нет аккаунта на Bookriver?
      </SCNoAccountLabel>
      <SCLink onClick={onRegisterClick}>
        Зарегистрироваться
      </SCLink>
    </SCLoginForm>
  );
};

const SCLoginForm = styled.form`
  text-align: center;
`;

const SCRowSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 320px;
  margin: 0 auto 22px auto;

  svg {
    display: block;
    width: 48px !important;
    height: 48px !important;
  }
`;

const SCOr = styled.div`
  ${TextSmallStyle};
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 41px;

  &:before {
    content: "";
    width: 50%;
    height: 1px;
    background: var(--gray3-color);
    margin-right: 13px;
  }

  &:after {
    content: "";
    width: 50%;
    height: 1px;
    background: var(--gray3-color);
    margin-left: 13px;
  }
`;

const SCFieldsWrap = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const SCInput = styled(Input)`
  background: var(--white-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 2px;
  height: 44px;
`;

const SCRowRememberMe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--black-color);
  margin: 17px 0 25px 0;

  span {
    color: var(--black-color);
  }
`;

const SCCheckbox = styled(Checkbox)`
  span {
    ${TextRegStyle};
  }
`;

const SCLink = styled.a`
  ${TextBoldStyle};
`;

const SCButtonWrapp = styled.div`
  ${SCButton} {
    ${TextBoldStyle};
    margin-bottom: 30px;
    height: 44px;
  }
`;

const SCNoAccountLabel = styled.span`
  ${TextBoldStyle};
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
`;

const SCAuthProviderBackgroundTemplate = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const SCVkIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #506d91;
`;

const SCVKIcon = styled(VkIcon)`
  font-size: 48px;
  color: var(--white-color);
`;

const SCGoogleIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #FFFFFF;
  border: 1px solid var(--gray3-color);
`;

const SCGoogleIcon = styled(GoogleIcon)`
  font-size: 26px;
`;

const SCMailIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #1C4685;
`;

const SCMailIcon = styled(MailIcon)`
  font-size: 26px;
  color: #EA9845;
`;

const SCYandexIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #FFFFFF;
  border: 1px solid var(--gray3-color);
`;

const SCYandexIcon = styled(YandexIcon)`
  font-size: 26px;
  color: #EA1B4D;
`;

const SCAppleIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: var(--black-color);
`;

const SCAppleIcon = styled(AppleIcon)`
  font-size: 26px;
  color: var(--white-color);
`;
