import React from 'react';

import { RecoveryForm } from '~/feature/authorization/RecoveryModal/RecoveryForm';
import { useAppDispatch, useAppSelector } from '~/store';

import {
  AuthorizationModalState,
  openModal,
} from '../authorizationModal.slice';
import { SCAuthModal } from '../LoginModal/LoginModal';

export type RecoveryModalProps = {};

export const RecoveryModal: React.FC<RecoveryModalProps> = () => {
  const dispatch = useAppDispatch();
  const { openedModal, successRecovery } = useAppSelector<AuthorizationModalState>(
    (state) => state.authorizationModal,
  );

  const onLoginClick = () => {
    dispatch(openModal('login'));
  };

  const closeModal = () => {
    dispatch(openModal(''));
  };

  return (
    <SCAuthModal
      onCancel={closeModal}
      open={openedModal === 'recovery'}
    >
      <RecoveryForm successRecovery={successRecovery} onEnterClick={onLoginClick} />
    </SCAuthModal>
  );
};
