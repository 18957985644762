export const environments = {
  get isClient() {
    return typeof window !== 'undefined';
  },
  get isProduction() {
    return process.env.NEXT_PUBLIC_PROCESS_ENVIRONMENT === 'production';
  },
  get isLocal() {
    return process.env.NEXT_PUBLIC_BASE_FRONT_URL === 'http://localhost:3000';
  },
};

export const fileSizes = {
  get byte() {
    return 1;
  },
  get kilobyte() {
    return this.byte * 1024;
  },
  get megabyte() {
    return this.kilobyte * 1024;
  },
  get gigabyte() {
    return this.megabyte * 1024;
  },
};

export const apiStatusCodes = {
  get success() {
    return 200;
  },
  get notValidError() {
    return 422;
  },
  get error() {
    return 500;
  },
  get notFound() {
    return 500;
  },
  get accessDenied() {
    return 403;
  },
};

export const time = {
  get minute() {
    return 60;
  },
  get hour() {
    return this.minute * 60;
  },
  get day() {
    return this.hour * 24;
  },
  get year() {
    return this.day * 365;
  },
};

export const httpMethods = {
  get GET() {
    return 'GET';
  },
  get POST() {
    return 'POST';
  },
};
