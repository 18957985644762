import { unwrapResult } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import React, {
  FC, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Button, SCButton } from '~/atomic/atom/Button';
import { Input } from '~/atomic/atom/Input';
import { Field } from '~/atomic/molecula/Field';
import {
  PageTitleStyle, TextBoldStyle, TextRegStyle,
} from '~/atomic/Typography';
import { forgot } from '~/feature/authorization/auth.data';
import { authSelector } from '~/feature/authorization/auth.selector';
import { authModalActions } from '~/feature/authorization/authorizationModal.slice';
import { useAppDispatch, useAppSelector } from '~/store';

interface RecoveryFormProps {
  onEnterClick: () => void;
  successRecovery: boolean;
}

export const RecoveryForm: FC<RecoveryFormProps> = ({ onEnterClick, successRecovery }) => {
  const dispatch = useAppDispatch();
  const [isSuccessful, setIsSuccesful] = useState(successRecovery);
  const { loading } = useAppSelector(authSelector);
  const recoveryForm = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .required('Обязательное поле')
        .email('Введен неккоректный email адрес'),
    }),
    validateOnBlur: false,
    onSubmit: (values, FormikHelpers) => {
      dispatch(forgot({ email: values.email }))
        .then(unwrapResult)
        .then(() => {
          setIsSuccesful(true);
        });
    },
  });

  useEffect(() => {
    return () => {
      dispatch(authModalActions.setSuccessRecovery(false));
      setIsSuccesful(false);
    };
  }, []);

  return (
    <SCRecoveryForm onSubmit={recoveryForm.handleSubmit}>
      <SCTitle>Восстановление пароля</SCTitle>
      {isSuccessful ? (
        <SCSuccessful>
          <SCSuccessfulIcon src="/icon/success.svg" alt="" />
          <SCSuccessMessage>
            Инструкция по восстановлению пароля отправлена на ваш e-mail
          </SCSuccessMessage>
        </SCSuccessful>
      ) : (
        <SCRecovery>
          <Field
            error={recoveryForm.errors.email}
          >
            <SCInput
              name="email"
              placeholder="Введите e-mail"
              value={recoveryForm.values.email}
              onChange={recoveryForm.handleChange}
            />
          </Field>
          <SCButtonWrapp>
            <Button
              type="primary"
              htmlType="submit"
              isLoading={loading.forgot === true}
              block
            >
              Восстановить
            </Button>
          </SCButtonWrapp>
          <SCLink onClick={onEnterClick}>
            Войти
          </SCLink>
        </SCRecovery>
      )}
    </SCRecoveryForm>
  );
};

const SCRecovery = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const SCRecoveryForm = styled.form`
  text-align: center;
`;

const SCInput = styled(Input)`
  height: 44px;
`;

const SCButtonWrapp = styled.div`
  ${SCButton} {
    ${TextBoldStyle};
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const SCLink = styled.a`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`;

const SCTitle = styled.h2`
  ${PageTitleStyle};
  margin: 0 0 25px 0;
`;

const SCSuccessfulIcon = styled.img`
  width: 72px;
  height: 72px;
  margin-bottom: 32px;
`;

const SCSuccessMessage = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
  max-width: 320px;
`;

const SCSuccessful = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
