import { createSelector } from '@reduxjs/toolkit';

import { CompleteStatusEnum } from '~/api/book/bookApiTypes';
import { NEXT_BOOK_FORMAT_PAGE_STEP } from '~/atomic/page/book-reader/reader.data';
import { bookSelector } from '~/feature/book/book.selector';
import { isCanNotBuyCurrentBookSelector } from '~/feature/book/buyBook/buyBook.selector';
import {
  chapterSelector, ebookChaptersSelector, firstPublishChapterSelector,
  isChapterFirstFourChaptersSelector, isHaveBuyPublishChaptersSelector,
  lastPublishAvailableChapterSelector,
  lastPublishChapterSelector, secondPublishChapterSelector,
} from '~/feature/chapter/chapter.selector';
import { isTabletSizeSelector } from '~/feature/media/windowSize.selector';
import { userSelector } from '~/feature/user/user.selector';
import { GraphqlEChapter } from '~/graphql/books/factory/book/EBookChapterFactoryTypes';
import { RootState } from '~/store';

export const readerSelector = (state: RootState) => state.reader.reader;

export const isBookFormatBookReaderSelector = createSelector(
  readerSelector,
  isTabletSizeSelector,
  ({ textSettings }, isMobileSize) => {
    return textSettings.isBookFormat && !isMobileSize;
  },
);

export const isLastBookFormatPageSelector = createSelector(
  readerSelector,
  isTabletSizeSelector,
  isBookFormatBookReaderSelector,
  ({ openBookFormatPage, bookFormatCountPages }, isLaptopDevice, isBookFormat) => {
    if (!isBookFormat) return true;
    const countListsOnPage = !isLaptopDevice ? 2 : 1;
    return (openBookFormatPage + NEXT_BOOK_FORMAT_PAGE_STEP) * countListsOnPage >= bookFormatCountPages;
  },
);

export const isFirstBookFormatPageSelector = createSelector(
  readerSelector,
  ({ openBookFormatPage }) => {
    return openBookFormatPage === 0;
  },
);

export const isHaveNextBookInCycleSelector = createSelector(
  readerSelector,
  ({ nextBookInCycle }) => !!nextBookInCycle?.id,
);

export const isLastChapterInReaderSelector = (chapterId: string) => createSelector(
  lastPublishChapterSelector,
  lastPublishAvailableChapterSelector,
  (
    lastChapter,
    lastAvailableChapter,
  ) => {
    const isLastChapter = (lastChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0);
    const isLastAvailableChapter = (lastAvailableChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0);
    return (isLastChapter || isLastAvailableChapter);
  },
);

export const isFirstChapterInReaderSelector = (chapterId: string | number) => createSelector(
  firstPublishChapterSelector,
  (firstChapter) => {
    return (firstChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0);
  },
);
export const isSecondChapterInReaderSelector = (chapterId: string | number) => createSelector(
  secondPublishChapterSelector,
  (secondChapter) => {
    return (secondChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0);
  },
);

export const isLastChapterSelector = (chapterId: string) => createSelector(
  lastPublishChapterSelector,
  (lastPublishChapter) => (lastPublishChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0),
);

export const isLastAvailableChapterSelector = (chapterId: string) => createSelector(
  lastPublishAvailableChapterSelector,
  (lastPublishAvailableChapter) => (lastPublishAvailableChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0),
);

export const isShowAdvertsSelector = (chapterId: string) => createSelector(
  isLastChapterSelector(chapterId),
  isLastAvailableChapterSelector(chapterId),
  isChapterFirstFourChaptersSelector(chapterId),
  isBookFormatBookReaderSelector,
  isAvailableReaderChapterSelector(chapterId),
  readerChapterSelector(chapterId),
  (
    isLastChapter,
    isLastAvailableChapter,
    isChapterFirstFourChapters,
    isBookFormat,
    isAvailableReaderChapter,
    currentReaderChapter,
  ) => !(isLastChapter || isLastAvailableChapter)
    && !isChapterFirstFourChapters
    && !isBookFormat
    && isAvailableReaderChapter
    && currentReaderChapter.free !== true,
);

export const isEndOfBookReaderSelector = (chapterId: string) => createSelector(
  isLastBookFormatPageSelector,
  isLastChapterSelector(chapterId),
  (isLastBookFormatPage, isLastChapterInReader) => {
    return isLastBookFormatPage && isLastChapterInReader;
  },
);

export const isShowRecommendedBooksInReaderSelector = (chapterId: string) => createSelector(
  isEndOfBookReaderSelector(chapterId),
  bookSelector,
  isAvailableReaderChapterSelector(chapterId),
  isHaveNextBookInCycleSelector,
  (isEndOfBook, { currentGraphqlBook }, isAvailableReaderChapter, isHaveNextBookInCycle) => isEndOfBook
    && (currentGraphqlBook?.statusComplete !== CompleteStatusEnum.Complete || !isHaveNextBookInCycle)
    && isAvailableReaderChapter,
);

export const readerChapterSelector = (chapterId: string) => createSelector(
  ebookChaptersSelector,
  (chapters) => {
    return chapters?.find((chapter) => {
      return String(chapter.chapterId) === String(chapterId);
    });
  },
);

export const isAvailableReaderChapterSelector = (chapterId: string) => createSelector(
  readerChapterSelector(chapterId),
  chapterSelector,
  (chapter, { currentChapter }) => (currentChapter && 'available' in currentChapter ? currentChapter.available : chapter?.available),
);

export const isLoadingBookReaderSelector = createSelector(
  chapterSelector,
  readerSelector,
  ({ isGetPublishChaptersLoading }, { loading }) => isGetPublishChaptersLoading || loading,
);

export const firstPaidChapterSelector = createSelector(
  ebookChaptersSelector,
  bookSelector,
  (chapters, { currentBookProperties }) => (chapters.length
    ? chapters?.[currentBookProperties.freeChaptersCount]
    : null),
);

export const isCurrentChapterIsLastAvailableSelector = (chapterId: string) => createSelector(
  lastPublishAvailableChapterSelector,
  (lastAvailableChapter) => (lastAvailableChapter?.chapterId ?? 0) === Number(chapterId) ?? 0,
);

export const bookReaderNextChapterSelector = createSelector(
  ebookChaptersSelector,
  chapterSelector,
  (
    chapters,
    { currentChapter },
  ) => {
    const currentChapterIndex = chapters
      .findIndex((chapter) => chapter.chapterId === Number(currentChapter.chapterId));

    return chapters[currentChapterIndex + 1] ?? {} as Partial<GraphqlEChapter>;
  },
);

export const bookReaderPrevChapterSelector = createSelector(
  ebookChaptersSelector,
  chapterSelector,
  (
    chapters,
    { currentChapter },
  ) => {
    const currentChapterIndex = chapters
      .findIndex((chapter) => chapter.chapterId === Number(currentChapter.chapterId));

    return chapters[currentChapterIndex - 1] ?? {} as Partial<GraphqlEChapter>;
  },
);

export const isShowControlLinePrevButtonSelector = (chapterId: string) => createSelector(
  isBookFormatBookReaderSelector,
  readerSelector,
  ebookChaptersSelector,
  firstPublishChapterSelector,
  bookReaderPrevChapterSelector,
  (
    isBookFormat,
    { openBookFormatPage },
    chapters,
    firstChapter,
    prevChapter,
  ) => {
    const isFirstChapter = (firstChapter?.chapterId ?? 0) === (Number(chapterId) ?? 0);
    const prevChapterId = prevChapter?.chapterId ?? '';

    return isBookFormat
      ? openBookFormatPage !== 0 || !isFirstChapter
      : prevChapterId !== '';
  },
);

export const controlLinePrevButtonTextSelector = createSelector(
  isBookFormatBookReaderSelector,
  bookReaderPrevChapterSelector,
  (isBookFormat, prevChapter) => {
    if (isBookFormat) {
      return 'Следующая страница';
    }

    return prevChapter.name;
  },
);

export const isShowControlLineNextButtonSelector = (chapterId: string) => createSelector(
  isBookFormatBookReaderSelector,
  isLastBookFormatPageSelector,
  isCurrentChapterIsLastAvailableSelector(chapterId),
  bookSelector,
  ebookChaptersSelector,
  isCanNotBuyCurrentBookSelector,
  userSelector,
  isHaveBuyPublishChaptersSelector,
  bookReaderNextChapterSelector,
  (
    isBookFormat,
    isLastBookFormatPage,
    isLastAvailableChapter,
    { currentGraphqlBook },
    chapters,
    isCanNotBuyBook,
    { user },
    isHavePaidChapters,
    nextChapter,
  ) => {
    const isCurrentUserAuthorBook = String(currentGraphqlBook?.author?.id) === String(user.id);

    return isBookFormat
      ? (!isLastBookFormatPage || !isLastAvailableChapter)
      && ((!isCanNotBuyBook && !isCurrentUserAuthorBook) || (!isLastBookFormatPage || !isLastAvailableChapter))
      : (!isLastAvailableChapter
        || isHavePaidChapters
        || nextChapter.available)
      && ((!isCanNotBuyBook && !isCurrentUserAuthorBook) || !isLastAvailableChapter);
  },
);

export const controlLineNextButtonTextSelector = createSelector(
  isBookFormatBookReaderSelector,
  bookReaderNextChapterSelector,
  (isBookFormat, nextChapter) => {
    if (isBookFormat) {
      return 'Следующая страница';
    }

    return nextChapter.name;
  },
);

export const isShowControlLineNextBookButtonSelector = (chapterId: string) => createSelector(
  isEndOfBookReaderSelector(chapterId),
  isHaveNextBookInCycleSelector,
  (isEndBook, isHaveNextBookInCycle) => isEndBook && isHaveNextBookInCycle,
);

export const recommendedBookInReaderSelector = createSelector(
  bookSelector,
  readerSelector,
  ({ bookList }, { otherAuthorBooksWithoutCurrentCycleBooks }) => {
    if (
      bookList
      && bookList.length
      && otherAuthorBooksWithoutCurrentCycleBooks
      && otherAuthorBooksWithoutCurrentCycleBooks.length
    ) {
      if (bookList.length < 5) {
        return otherAuthorBooksWithoutCurrentCycleBooks.concat(bookList);
      }
      return otherAuthorBooksWithoutCurrentCycleBooks.slice(0, 3).concat(bookList);
    }
    return [];
  },
);
