import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';

import { HttpProblemError } from '~/api/provider/providerErrors';
import { reactQueryClient } from '~/api/reactQuery';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { abonnementGraphql } from '~/graphql/books/abonnementGraphql';
import {
  AbonnementTypeEnum,
  UserAbonnement,
} from '~/graphql/books/factory/abonement/AbonnementFactoryTypes';
import { useAppSelector } from '~/store';

export const getAbonementsCashName = 'getAbonements';

export const useGetAbonnements = (bookId: number | null = null) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  useEffect(() => {
    reactQueryClient.removeQueries(getAbonementsCashName);
  }, [isLoggedIn]);

  return useQuery(
    getAbonementsCashName,
    async () => {
      const res = await abonnementGraphql.getAbonements({ bookId });
      res.abonnementsTypes.sort((a, b) => a.price - b.price);
      return res;
    },
  );
};

export const getUserAbonnementCashName = 'getUserAbonnement';

export const isHaveUserAbonnementErrorFunc = (userAbonnement: UserAbonnement) => userAbonnement?.paymentError;
export const getUserAbonnementError = (userAbonnement: UserAbonnement) => (userAbonnement?.paymentError
  ? userAbonnement?.paymentError ?? 'технический сбой'
  : '');

export const useGetUserAbonnement = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { user } = useAppSelector(userSelector);

  useEffect(() => {
    if (!isLoggedIn) {
      reactQueryClient.removeQueries(getUserAbonnementCashName);
    }
  }, [isLoggedIn]);

  return useQuery(
    getUserAbonnementCashName,
    () => abonnementGraphql.getUserAbonnement(),
    {
      enabled: isLoggedIn && Boolean(user?.abonnement?.status),
      cacheTime: 0,
    },
  );
};

export const useDisableAbonnementAutoRenewal = () => {
  return useMutation(
    async () => {
      try {
        await abonnementGraphql.disableAbonnementAutoRenewal();
        const { message } = await import('~/atomic/atom/message');
        ym('reachGoal', 'success-disable-auto-renewal');
        message.success('Автопродление абонемента отменено');
      } catch (e) {
        if (e instanceof HttpProblemError) {
          const { message } = await import('~/atomic/atom/message');
          message.error(e.message);
        }
      }
    },
    {
      onSuccess: () => {
        reactQueryClient.invalidateQueries({ queryKey: getUserAbonnementCashName });
      },
    },
  );
};

export const useEnableAbonnementAutoRenewal = () => {
  return useMutation(
    async () => {
      try {
        await abonnementGraphql.enableAbonnementAutoRenewal();
        const { message } = await import('~/atomic/atom/message');
        ym('reachGoal', 'success-enable-auto-renewal');
        message.success('Автопродление абонемента включено');
      } catch (e) {
        if (e instanceof HttpProblemError) {
          const { message } = await import('~/atomic/atom/message');
          message.error(e.message);
        }
      }
    },
    {
      onSuccess: () => {
        reactQueryClient.invalidateQueries({ queryKey: getUserAbonnementCashName });
      },
    },
  );
};

export const useSwitchAbonnement = () => {
  return useMutation(
    async (abonnementType: AbonnementTypeEnum) => {
      try {
        await abonnementGraphql.switchAbonnement({ abonnementType });
        const { message } = await import('~/atomic/atom/message');
        message.success('Абонемент успешно переключён!');
      } catch (e) {
        if (e instanceof HttpProblemError) {
          const { message } = await import('~/atomic/atom/message');
          message.error(e.message);
        }
      }
    },
    {
      onSuccess: () => {
        reactQueryClient.invalidateQueries({ queryKey: getUserAbonnementCashName });
      },
    },
  );
};
