import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';

import { financeApi } from '~/api/finance/financeApi';
import {
  Methods, PayEntitiesEnum, TransactionInfo, TransactionResult,
} from '~/api/finance/financeApiTypes';
import { getAdAccountInfo } from '~/atomic/page/campaigns/AdAccount/adAccount.data';
import { getBalance } from '~/atomic/page/finance/financePage.data';
import { getOperations } from '~/atomic/page/finance/OperationsTable/operationTable.data';
import { loadAudioPlayer } from '~/feature/audio/audioPlayer.data';
import {
  checkUserTransaction,
  checkUserTransactionFromLocalStorage,
} from '~/feature/book/buyBook/buyBook.data';
import {
  paymentResultActions,
  paymentResultSliceName,
} from '~/feature/book/paymentResult/paymentResult.slice';
import { me } from '~/feature/user/user.data';
import { userActions } from '~/feature/user/user.slice';
import { getQueriesFromUrl } from '~/feature/utils/getQueriesFromUrl/getQueriesFromUrl';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { RootState } from '~/store';

interface PaymentTransactionParams {
  confirmationUrl?: string;
  confirmationToken?: string;
  transactionId?: number;
  methodPayment?: Methods;
  successTransactionHandler?: () => void;
  failedTransactionHandler?: () => void;
}

export const paymentTransaction = createAsyncThunk<
void,
PaymentTransactionParams,
{ state: RootState }
>(
  `${paymentResultSliceName}/paymentTransaction`,
  async ({
    methodPayment,
    failedTransactionHandler,
    successTransactionHandler,
    confirmationUrl,
    transactionId,
    confirmationToken,
  }, thunkAPI) => {
    const url = confirmationUrl ?? confirmationToken ?? '';
    if (methodPayment === Methods.balance || methodPayment === Methods.savedBankCard) {
      await successTransactionHandler();
    } else if (!url) {
      try {
        if (transactionId) {
          let transactionResult: TransactionResult;

          if (methodPayment === Methods.Tinkoff) {
            const result = await financeApi.transactionTinkoffResult({ transactionId: Number(transactionId) });

            transactionResult = result.purchase;
          } else {
            const result = await financeApi.transactionResult({ transactionId: Number(transactionId) });

            transactionResult = result.purchase;
          }

          if (transactionResult) {
            thunkAPI.dispatch(paymentResultActions.changeTransactionResult(transactionResult));
          }
        }
        await successTransactionHandler();
      } catch (error) {
        if (error?.message) {
          thunkAPI.dispatch(paymentResultActions.changeErrorMessage(error?.message));
        }
        await failedTransactionHandler();
      }
    } else {
      window.location.href = url;
    }
  },
);

export const checkPaymentThunk = createAsyncThunk<
void,
void, {
  rejectValue: { error: string }; state: RootState;
}>(
  `${paymentResultSliceName}/buyBookTransaction`,
  async (
    _,
    thunkAPI,
  ) => {
    const successTransactionHandler = async ({
      payEntity, transactionResult, ...otherProps
    }: TransactionInfo & { transactionResult: TransactionResult }) => {
      if (payEntity === PayEntitiesEnum.AudioBook) {
        const { bookPage } = thunkAPI.getState().book;

        thunkAPI.dispatch(loadAudioPlayer({
          book: {
            slug: bookPage?.slug,
            name: bookPage?.name,
            id: bookPage?.id,
            author: bookPage?.author,
          },
          chapters: bookPage?.audiobook?.chapters?.map((chapter) => ({
            id: chapter.chapterId,
            name: chapter.name,
            url: chapter.audioUrl,
            duration: chapter.duration,
            available: chapter.available,
          })),
        }));
        thunkAPI.dispatch(paymentResultActions.openModal('buyAudioBookSuccess'));
      }

      if (payEntity === PayEntitiesEnum.book) {
        const isReaderPage = Router.pathname.startsWith('/reader');
        const isBookPage = Router.pathname.startsWith('/book');

        thunkAPI.dispatch(paymentResultActions.openModal('buyBookSuccess'));

        if (!isReaderPage && !isBookPage) {
          ym('reachGoal', 'success-buy-book-from-storage', { order_price: transactionResult?.incomeByPurchase });
        }
      }

      if (payEntity === PayEntitiesEnum.reward) {
        thunkAPI.dispatch(paymentResultActions.openModal('rewardAuthorSuccess'));
      }

      if (payEntity === PayEntitiesEnum.rewardInComment) {
        thunkAPI.dispatch(paymentResultActions.openModal('rewardInCommentSuccess'));
      }

      if (payEntity === PayEntitiesEnum.Abonnement) {
        thunkAPI.dispatch(me());
        thunkAPI.dispatch(paymentResultActions.openModal('buyAbonnementSuccess'));
      }

      if (payEntity === PayEntitiesEnum.AdAccountReplenishment) {
        thunkAPI.dispatch(paymentResultActions.openModal('adAccountReplenishmentSuccess'));
        thunkAPI.dispatch(getAdAccountInfo());
      }

      if (payEntity === PayEntitiesEnum.AssetBalance) {
        thunkAPI.dispatch(me());
        thunkAPI.dispatch(getBalance());
        thunkAPI.dispatch(getOperations({}));
        thunkAPI.dispatch(paymentResultActions.openModal('assetBalanceSuccess'));
      }
    };

    const failedTransactionHandler = ({ payEntity }: TransactionInfo) => {
      if (payEntity === PayEntitiesEnum.AudioBook) {
        thunkAPI.dispatch(paymentResultActions.openModal('buyAudioBookReject'));
      }

      if (payEntity === PayEntitiesEnum.book) {
        thunkAPI.dispatch(paymentResultActions.openModal('buyBookReject'));
      }

      if (payEntity === PayEntitiesEnum.reward) {
        thunkAPI.dispatch(paymentResultActions.openModal('rewardAuthorReject'));
      }

      if (payEntity === PayEntitiesEnum.Abonnement) {
        thunkAPI.dispatch(paymentResultActions.openModal('buyAbonnementReject'));
      }

      if (payEntity === PayEntitiesEnum.rewardInComment) {
        thunkAPI.dispatch(paymentResultActions.openModal('rewardInCommentReject'));
      }

      if (payEntity === PayEntitiesEnum.AdAccountReplenishment) {
        thunkAPI.dispatch(paymentResultActions.openModal('adAccountReplenishmentReject'));
        thunkAPI.dispatch(getAdAccountInfo());
      }

      if (payEntity === PayEntitiesEnum.AssetBalance) {
        thunkAPI.dispatch(paymentResultActions.openModal('assetBalanceReject'));
      }
    };

    const {
      transactionId, password, methodPayment, payEntity,
    } = await getQueriesFromUrl({ searchedQueries: ['transactionId', 'payEntity', 'methodPayment', 'password'] });

    if (password) thunkAPI.dispatch(userActions.updateUser({ password }));

    if (transactionId && payEntity) {
      await thunkAPI.dispatch(checkUserTransaction({
        transactionId: Number(transactionId),
        payEntity: payEntity as PayEntitiesEnum,
        methodPayment: methodPayment as Methods,
        successTransactionHandler,
        failedTransactionHandler,
      }));
    } else {
      await thunkAPI.dispatch(checkUserTransactionFromLocalStorage({
        failedTransactionHandler,
        successTransactionHandler,
      }));
    }
  },
);
