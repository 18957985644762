import { createAsyncThunk } from '@reduxjs/toolkit';

import { ViewChapterParams } from '~/api/book/statisticsApiTypes';

import { statisticsApi } from '../../api/book/statisticsApi';

export const viewChapter = createAsyncThunk<
void, ViewChapterParams, { rejectValue: { error: string } }
>(
  'statistics/viewChapter',
  async (data, thunkAPI) => {
    try {
      await statisticsApi.viewChapter(data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
