import { BookIdType } from '~/api/book/bookApiTypes';
import { handleRejectedRequest, HttpProblemError } from '~/api/provider/providerErrors';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import {
  AbonnementTypeFactory,
  UserAbonnementFactory,
} from '~/graphql/books/factory/abonement/AbonementFactory';
import {
  AbonnementType,
  AbonnementTypeEnum,
} from '~/graphql/books/factory/abonement/AbonnementFactoryTypes';
import {
  AddBookToAbonnementDocument,
  AddBookToAbonnementMutation,
  AddBookToAbonnementMutationVariables,
  DisableAbonnementAutoRenewalDocument,
  DisableAbonnementAutoRenewalMutation,
  EnableAbonnementAutoRenewalDocument,
  EnableAbonnementAutoRenewalMutation,
  GetAbonnementDocument,
  GetAbonnementQuery,
  GetAbonnementTypesDocument, GetAbonnementTypesInput,
  GetAbonnementTypesQuery,
  RemoveBookFromAbonnementDocument,
  RemoveBookFromAbonnementMutation,
  RemoveBookFromAbonnementMutationVariables,
  SwitchAbonnementDocument,
  SwitchAbonnementMutation,
  SwitchAbonnementMutationVariables,
} from '~/graphql/books/GraphQLGeneratedCode';

export interface AddBookToAbonnementParams {
  bookId: BookIdType;
}

export interface DeleteBookToAbonnementParams {
  bookId: BookIdType;
}

export interface GetAbonementsResult {
  abonnementsTypes: Array<AbonnementType>;
  numberOfBooks: number;
}

export type SwitchAbonnementParams = {
  abonnementType: AbonnementTypeEnum;
};

export const abonnementGraphql = {
  async getUserAbonnement() {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    const response = await booksGraphqlClient(getAuthTokenFromClient())
      .query<GetAbonnementQuery>({ query: GetAbonnementDocument });

    if ('errors' in response && response.errors.length) {
      throw new Error(response.errors[0].message);
    }

    if (response.data.getAbonnement.__typename === 'ProblemType') {
      throw new HttpProblemError(response.data.getAbonnement.message);
    }

    if (response.data.getAbonnement.__typename === 'GetAbonnementPayload' && response.data.getAbonnement.abonnement) {
      return UserAbonnementFactory.create(response.data.getAbonnement.abonnement);
    }

    throw new Error('getUserAbonnement data is not unhandled');
  },
  async getAbonements(input: GetAbonnementTypesInput): Promise<GetAbonementsResult> {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    try {
      const response = await booksGraphqlClient(getAuthTokenFromClient())
        .query<GetAbonnementTypesQuery>({ query: GetAbonnementTypesDocument, variables: { input: { bookId: input?.bookId } } });

      if (response?.errors?.length) {
        throw new Error(response?.errors?.[0].message);
      }

      const data = response.data?.getAbonnementTypes;

      if (data && 'abonnementTypes' in data) {
        return {
          abonnementsTypes: data.abonnementTypes.map(AbonnementTypeFactory.create),
          numberOfBooks: data.numberOfBooks,
        };
      }

      throw new Error('abonnementTypes is not exist in data');
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
  async addBookToAbonnement(input: AddBookToAbonnementParams) {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');
    const response = await booksGraphqlClient(getAuthTokenFromClient())
      .mutate<AddBookToAbonnementMutation, AddBookToAbonnementMutationVariables>({
      mutation: AddBookToAbonnementDocument,
      variables: { input: { bookId: input.bookId as number } },
    });

    if (response.data.addBookToAbonnement.__typename === 'ProblemType') {
      throw new HttpProblemError(response.data.addBookToAbonnement.message);
    }
  },
  async deleteBookFromAbonnement(input: DeleteBookToAbonnementParams) {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    const response = await booksGraphqlClient(getAuthTokenFromClient())
      .mutate<RemoveBookFromAbonnementMutation, RemoveBookFromAbonnementMutationVariables>({
      mutation: RemoveBookFromAbonnementDocument,
      variables: { input: { bookId: input.bookId as number } },
    });

    if (response?.errors?.length) {
      throw new Error(response?.errors?.[0].message);
    }
  },
  async disableAbonnementAutoRenewal() {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    const response = await booksGraphqlClient(getAuthTokenFromClient())
      .mutate<DisableAbonnementAutoRenewalMutation>({ mutation: DisableAbonnementAutoRenewalDocument });

    if (response.data.disableAbonnementAutoRenewal.__typename === 'ProblemType') {
      throw new HttpProblemError(response.data.disableAbonnementAutoRenewal.message);
    }

    if (response?.errors?.length) {
      throw new Error(response?.errors?.[0].message);
    }
  },
  async enableAbonnementAutoRenewal() {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    const response = await booksGraphqlClient(getAuthTokenFromClient())
      .mutate<EnableAbonnementAutoRenewalMutation>({ mutation: EnableAbonnementAutoRenewalDocument });

    if (response.data.enableAbonnementAutoRenewal.__typename === 'ProblemType') {
      throw new HttpProblemError(response.data.enableAbonnementAutoRenewal.message);
    }

    if (response?.errors?.length) {
      throw new Error(response?.errors?.[0].message);
    }
  },
  async switchAbonnement(data: SwitchAbonnementParams) {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    const response = await booksGraphqlClient(getAuthTokenFromClient())
      .mutate<SwitchAbonnementMutation, SwitchAbonnementMutationVariables>({
      mutation: SwitchAbonnementDocument,
      variables: { input: data },
    });

    if (response.data.switchAbonnement.__typename === 'ProblemType') {
      throw new HttpProblemError(response.data.switchAbonnement.message);
    }

    if (response?.errors?.length) {
      throw new Error(response?.errors?.[0].message);
    }
  },
};
