import { createSelector } from '@reduxjs/toolkit';

import {
  Book, BookTypeEnum, CompleteStatusEnum, MiniatureSizesEnum, PublishStatusEnum,
} from '~/api/book/bookApiTypes';
import { defaultCycleName } from '~/feature/cycle/cycleSlice';
import { capitalizeFirstLetter } from '~/lib/utils/capitalizeFirstLetter';
import { RootState } from '~/store';

export const bookSelector = (state: RootState) => state.book;

export const currentBookSelector = createSelector(
  bookSelector,
  (book) => book.currentBook,
);

export const currentGraphqlBookSelector = createSelector(
  bookSelector,
  (book) => book.currentGraphqlBook,
);

export const bookPageSelector = createSelector(
  bookSelector,
  (book) => book.bookPage,
);

export const isCurrentBookNovelTypeSelector = createSelector(
  currentBookSelector,
  (currentBook) => currentBook?.type === BookTypeEnum.Novel,
);

export const isCurrentBookStoryTypeSelector = createSelector(
  currentBookSelector,
  (currentBook) => currentBook?.type === BookTypeEnum.Story,
);

export const completeCurrentBookStatusSelector = createSelector(
  currentBookSelector,
  (currentBook) => currentBook.statusComplete,
);

export const isInProcessCurrentBookSelector = createSelector(
  completeCurrentBookStatusSelector,
  (bookStatus) => bookStatus === CompleteStatusEnum.Writing,
);

export const isInProcessBookPageSelector = createSelector(
  bookPageSelector,
  ({ statusComplete }) => statusComplete === CompleteStatusEnum.Writing,
);

export const isCompleteCurrentBookSelector = createSelector(
  completeCurrentBookStatusSelector,
  (bookStatus) => bookStatus === CompleteStatusEnum.Complete,
);

export const isHaveBookPropertiesSelector = createSelector(
  bookSelector,
  ({ currentBookProperties }) => !!currentBookProperties?.bookId,
);

export const isCurrentBookPublishedSelector = createSelector(
  currentBookSelector,
  (currentBook) => currentBook.statusPublish === PublishStatusEnum.Published,
);

export const isDefaultCycleSelector = createSelector(
  bookSelector,
  ({ currentBook }) => (currentBook?.cycle?.name ?? '') === defaultCycleName,
);

export const isDefaultCycleBookPageSelector = createSelector(
  bookPageSelector,
  (bookPage) => (bookPage?.cycle?.name ?? '') === defaultCycleName,
);

export const bookAuthorSelector = createSelector(
  bookSelector,
  ({ currentGraphqlBook }) => currentGraphqlBook?.author,
);

export const bookPageAuthorSelector = createSelector(
  bookSelector,
  ({ bookPage }) => bookPage?.author,
);

export const graphqlBookAuthorSelector = createSelector(
  bookSelector,
  ({ currentGraphqlBook }) => currentGraphqlBook?.author,
);

export const bookAuthorIdSelector = createSelector(
  bookSelector,
  ({ currentBook }) => currentBook.authors?.[0]?.id,
);

export const bookCoverUrlSelector = createSelector(
  bookSelector,
  ({ currentBook }) => currentBook?.coverImages?.find((size) => {
    return size?.sizeType === MiniatureSizesEnum.Original;
  })?.url ?? '',
);

const MAX_ANNOTATION_LENGTH = 200;

export const currentGraphqlBookAnnotationMetaSelector = createSelector(
  currentGraphqlBookSelector,
  (currentGraphqlBook) => {
    const annotation = currentGraphqlBook?.annotation ?? '';
    let newAnnotation = '';

    if (annotation.length > MAX_ANNOTATION_LENGTH) {
      const sentences = annotation.split('.');

      for (let i = 0; i < sentences.length; i += 1) {
        if (newAnnotation.length < MAX_ANNOTATION_LENGTH) {
          newAnnotation += `${sentences[i]}.`;
        } else {
          break;
        }
      }

      newAnnotation = `${newAnnotation.slice(0, MAX_ANNOTATION_LENGTH)
        .slice(0, -3)
        .trim()}...`;
    } else {
      newAnnotation = annotation;
    }
    return newAnnotation;
  },
);

export const isAddInMyBooksCurrentGraphqlBookSelector = createSelector(
  currentGraphqlBookSelector,
  (currentGraphqlBook) => !!currentGraphqlBook?.myBook?.readStatus,
);

export const isActivateProfitStartsSelector = createSelector(
  bookSelector,
  ({ currentBookProperties }) => !!currentBookProperties?.paidBookSubscription?.startedAt,
);

export const isShowDiscountGradeLineSelector = createSelector(
  bookPageSelector,
  (bookPage) => {
    if (bookPage?.free) return false;

    if (bookPage?.ebook?.available) {
      return !!bookPage?.ebook?.discount?.grade
      && bookPage?.ebook?.discount?.grade > 0
      && !bookPage?.myBook?.bought;
    }

    if (bookPage?.audiobook?.available) {
      return !!bookPage?.audiobook?.discount?.grade
        && bookPage?.audiobook?.discount?.grade > 0
        && !bookPage?.myBook?.bought;
    }
  },
);

export const isHaveCurrentBookDiscountSelector = createSelector(
  currentBookSelector,
  (currentBook) => (currentBook?.discount?.price ?? 0) !== currentBook.price,
);

export const isHaveCurrentAudioBookDiscountSelector = createSelector(
  bookPageSelector,
  (bookPage) => (bookPage?.audiobook?.discount?.price
      ?? bookPage?.audiobook?.price)
    !== bookPage?.audiobook?.price,
);

export const isHaveBuyPublishChaptersInBookPageSelector = createSelector(
  bookPageSelector,
  ({ ebook }) => {
    const paidChapters = ebook?.chapters?.filter((chapter) => !chapter.available);

    return paidChapters?.length > 0;
  },
);

export const isCanBuyAudioBookPageSelector = createSelector(
  bookPageSelector,
  ({ audiobook }) => {
    return audiobook?.canBuy && audiobook.available;
  },
);

export const isAvailableListerAudioBookSelector = (book: Partial<Book>) => createSelector(
  () => {
    return !!book?.audiobook?.canListen && book?.audiobook?.available;
  },
);

export const bookSelectors = createSelector(
  bookSelector,
  isDefaultCycleBookPageSelector,
  ({ bookPageCycleBooks, otherBooksByAuthor }, isDefaultCycle) => {
    if (bookPageCycleBooks.length && !isDefaultCycle) {
      return bookPageCycleBooks.filter((_, i) => i < 2);
    }

    return otherBooksByAuthor.filter((_, i) => i < 2);
  },
);

export const bookPageDiscountSelector = createSelector(
  bookPageSelector,
  (bookPage) => {
    return bookPage?.ebook?.available ? bookPage?.ebook.discount : bookPage?.audiobook?.discount;
  },
);

export const isBookPageEBookSelector = createSelector(
  bookPageSelector,
  (bookPage) => {
    return bookPage?.ebook?.available;
  },
);

export const isBookPageAudioBookSelector = createSelector(
  bookPageSelector,
  (bookPage) => {
    return bookPage?.audiobook?.available;
  },
);

export const isHaveBookPageDiscountSelector = createSelector(
  bookPageSelector,
  bookPageDiscountSelector,
  isBookPageEBookSelector,
  ({ ebook, audiobook }, discount, isBookPageEBook) => (discount?.price ?? 0) !== (
    isBookPageEBook
      ? ebook?.price
      : audiobook?.price
  ),
);

const MAX_BOOK_ANNOTATION_LENGTH = 120;
const MAX_BOOK_TAGS_LENGTH = 3;

const getAnnotation = (annotation: string) => {
  if (annotation.length > MAX_BOOK_ANNOTATION_LENGTH) {
    return `${annotation.slice(0, MAX_BOOK_ANNOTATION_LENGTH).slice(0, -3).trim()}...`;
  }
  return annotation;
};

export const bookPageSeoSelector = createSelector(
  bookPageSelector,
  (book) => {
    const authorName = book?.author?.name ?? '';
    const bookName = book?.name;
    const firstGenreName = book?.genres?.[0]?.name ?? '';
    const annotation = getAnnotation(book?.annotation ?? '');
    const tags = book?.tags?.slice(0, MAX_BOOK_TAGS_LENGTH) ?? [];
    const tagNames = tags.map(({ name }) => name);

    const title = `${bookName} – ${authorName}, ${firstGenreName} | BookRiver`;
    const description = `${annotation} ${capitalizeFirstLetter(tagNames.join(', '))}.`;

    return {
      title,
      description,
    };
  },
);
