import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { myBooksApi } from '~/api/account/myBooksApi';
import {
  GetPlaceReadByBookSlugParams,
  GetPlaceReadResult,
  SavePlaceReadParams,
} from '~/api/account/myBooksApiTypes';
import { BookIdType } from '~/api/book/bookApiTypes';
import { campaignsApi } from '~/api/finance/marketing/campaignsApi';
import { reactQueryClient } from '~/api/reactQuery';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import {
  isBookFormatBookReaderSelector,
  isFirstBookFormatPageSelector, isFirstChapterInReaderSelector,
  isLastBookFormatPageSelector, isSecondChapterInReaderSelector,
  readerSelector,
} from '~/atomic/page/book-reader/reader.selectors';
import {
  bookReaderSliceName,
  readerActions,
  readerDefaultSettings,
  textSettingsName,
} from '~/atomic/page/book-reader/reader.slice';
import { getUserAbonnementCashName } from '~/atomic/page/bookSubscriptions/abonnementPage.data';
import { getUserEmailInLocalStorage } from '~/feature/authorization/SaveUserLoginModal/saveUserEmailInLocalStorage';
import { bookSelector } from '~/feature/book/book.selector';
import { openBuyBookModal } from '~/feature/book/buyBook/buyBook.data';
import { getPublishedChapterListByBookSlugFromGraphql } from '~/feature/chapter/chapter.data';
import {
  chapterSelector,
  ebookChaptersSelector,
  lastPublishAvailableChapterSelector,
} from '~/feature/chapter/chapter.selector';
import { chapterActions } from '~/feature/chapter/chapter.slice';
import { defaultCycleName, getCyclesByAuthorUsername } from '~/feature/cycle/cycleSlice';
import { viewChapter } from '~/feature/statistics/viewChapter';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { me } from '~/feature/user/user.data';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { booksGraphql, GetEBookChapterParams } from '~/graphql/books/booksGraphql';
import { GraphqlEChapter } from '~/graphql/books/factory/book/EBookChapterFactoryTypes';
import { GraphqlEChapterWithContent } from '~/graphql/books/factory/book/EChapterWithContentFactoryTypes';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const NEXT_BOOK_FORMAT_PAGE_STEP = 1;

export const bookIdsForAddMyBooksName = 'bookIdsForAddMyBooks';

export const getReaderTextChapter = createAsyncThunk<GraphqlEChapterWithContent, GetEBookChapterParams & { bookSlug: string }, {
  rejectValue: { error: string }, state: RootState
}>(
  `${bookReaderSliceName}/getTextChapter`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(chapterActions.changeIsCurrentChapterLoading(true));
      const result = await booksGraphql.getEbookChapter({ chapterId: data.chapterId });
      thunkAPI.dispatch(chapterActions.changeCurrentChapter(result));

      if (result.available === false) {
        await thunkAPI.dispatch(me());
        reactQueryClient.removeQueries(getUserAbonnementCashName);
        thunkAPI.dispatch(getPublishedChapterListByBookSlugFromGraphql({ slug: data.bookSlug }));
        thunkAPI.dispatch(openBuyBookModal());
      }

      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(chapterActions.changeIsCurrentChapterLoading(false));
    }
  },
);

export const sendGoalBuyBookToVkPixel = createAsyncThunk<void, { chapterId: number; }, {
  state: RootState
}>(
  `${bookReaderSliceName}/sendGoalBuyBookToVkPixel`,
  async (data, thunkAPI) => {
    try {
      const { currentBookProperties, currentGraphqlBook } = thunkAPI.getState().book;
      const chapters = ebookChaptersSelector(thunkAPI.getState());

      if (currentGraphqlBook?.ebook?.bought) {
        const firstPaidChapter = chapters[currentBookProperties.freeChaptersCount];

        if (firstPaidChapter && data.chapterId === Number(firstPaidChapter?.chapterId)) {
          const vkPixelResult = await campaignsApi.getPixel({ bookId: currentGraphqlBook.id });
          if ('data' in vkPixelResult && vkPixelResult.data?.vk) {
            if (localStorage) {
              const buyBooks = JSON.parse(localStorage.getItem('sendGoalBuyBookToVkPixel')) ?? [];
              if (!buyBooks.includes(currentGraphqlBook.slug)) {
                // @ts-ignore
                window.VK.Retargeting.Init(vkPixelResult.data.vk);
                // @ts-ignore
                window.VK.Goal('purchase', { value: currentGraphqlBook.ebook.price / 2 });

                localStorage.setItem(
                  'sendGoalBuyBookToVkPixel',
                  JSON.stringify([...buyBooks, currentGraphqlBook.slug]),
                );
              }
            }
          }
        }
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: e.message });
    }
  },
);

export const savePlaceRead = createAsyncThunk<
void,
Omit<SavePlaceReadParams, 'bookId'>,
{ rejectValue: { error: string }, state: RootState }
>(
  `${bookReaderSliceName}/savePlaceRead`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { currentGraphqlBook } = thunkAPI.getState().book;
      const { saveChapterProgress, saveChapterId } = readerSelector(thunkAPI.getState());

      thunkAPI.dispatch(readerActions.setIsSavePlaceReadLoading(true));

      let newChapterProgress = data.chapterProgress;

      if (data.chapterProgress < 0) {
        newChapterProgress = 0;
      }

      if (data.chapterProgress > 99) {
        newChapterProgress = 100;
      }

      const progressDifferent = Math.abs(newChapterProgress - saveChapterProgress);
      const isChangeChapterProgress = progressDifferent > 15
        && saveChapterProgress !== newChapterProgress;

      const isChangeChapter = data.chapterId !== saveChapterId;

      const isUpdateChapterProgress = isChangeChapterProgress || isChangeChapter;

      if (isChangeChapter) {
        newChapterProgress = 0;
      }

      if (isUpdateChapterProgress) {
        await myBooksApi.savePlaceRead({
          chapterId: data.chapterId,
          chapterProgress: newChapterProgress,
          bookId: currentGraphqlBook?.id,
        });

        thunkAPI.dispatch(readerActions.changeSaveChapterId(data.chapterId));
        thunkAPI.dispatch(readerActions.changeSaveChapterProgress(newChapterProgress));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(readerActions.setIsSavePlaceReadLoading(false));
    }
  },
);

export const getPlaceReadByBookSlug = createAsyncThunk<
GetPlaceReadResult,
GetPlaceReadByBookSlugParams,
{ rejectValue: { error: string }; }
>(
  `${bookReaderSliceName}/getPlaceReadByBookSlug`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await myBooksApi.getPlaceReadByBookSlug(data);

      if (result && 'data' in result) {
        thunkAPI.dispatch(readerActions.changeSaveChapterId(result.data.chapterId));
        thunkAPI.dispatch(readerActions.changeSaveChapterProgress(result.data.chapterProgress));
        return result.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getTextChapterInReader = createAsyncThunk<
void, { chapterId: string }, { state: RootState }
>(
  `${bookReaderSliceName}/getTextChapterInReader`,
  async (data, thunkAPI) => {
    const { isOpenDropMenu } = readerSelector(thunkAPI.getState());
    const { currentGraphqlBook } = thunkAPI.getState().book;

    try {
      thunkAPI.dispatch(readerActions.setLoading(true));
      unwrapResult(await thunkAPI.dispatch(getReaderTextChapter({ chapterId: Number(data.chapterId), bookSlug: currentGraphqlBook?.slug })));

      thunkAPI.dispatch(viewChapter({ chapterId: data.chapterId }));

      thunkAPI.dispatch(readerActions.setLoading(false));
    } catch (error) {
      thunkAPI.dispatch(readerActions.setLoading(false));
    } finally {
      if (isOpenDropMenu) {
        thunkAPI.dispatch(readerActions.changeOpenDropMenu(false));
      }
    }
  },
);

export const checkChapterOnAvailable = createAsyncThunk<
void, { chapterId: string }, { state: RootState }
>(
  `${bookReaderSliceName}/checkChapterOnAvailable`,
  async (data, thunkAPI) => {
    const { isGetPublishChaptersLoading } = chapterSelector(thunkAPI.getState());
    const chapters = ebookChaptersSelector(thunkAPI.getState());
    const lastAvailableChapter = lastPublishAvailableChapterSelector(thunkAPI.getState());
    const isLoggedIn = isLoggedInSelector(thunkAPI.getState());
    const isAvailableChapter = chapters
      .find((chapter) => String(chapter.chapterId) === data.chapterId)?.available;

    if ((!isAvailableChapter) && !isGetPublishChaptersLoading && chapters?.length) {
      if (!lastAvailableChapter?.chapterId) {
        await Router.push('/404');
        thunkAPI.dispatch(readerActions.setLoading(false));
      } else if (!isLoggedIn) {
        const userEmailFromStorage = unwrapResult(await thunkAPI.dispatch(getUserEmailInLocalStorage()));

        if (userEmailFromStorage) {
          thunkAPI.dispatch(readerActions.setIsOpenSaveUserLoginModalWithLoadSaveChapter(true));
        } else {
          thunkAPI.dispatch(readerActions.setIsOpenLoginModalWithLoadSaveChapter(true));
        }
      }
    }
  },
);

export const getSavedTextChapterInReader = createAsyncThunk<void, void, { state: RootState }>(
  `${bookReaderSliceName}/getSaveTextChapterInReader`,
  async (_, thunkAPI) => {
    const { currentGraphqlBook } = thunkAPI.getState().book;
    const { isOpenDropMenu } = readerSelector(thunkAPI.getState());

    const isCanGetTextChapterInReader = currentGraphqlBook?.id;

    if (isCanGetTextChapterInReader) {
      try {
        const [placeRead] = await Promise.all([
          thunkAPI.dispatch(getPlaceReadByBookSlug({ slug: currentGraphqlBook?.slug })),
          thunkAPI.dispatch(getPublishedChapterListByBookSlugFromGraphql({ slug: currentGraphqlBook?.slug })),
        ]);

        if (placeRead
          && getPlaceReadByBookSlug?.fulfilled?.match(placeRead)
          && placeRead?.payload?.chapterId) {
          await thunkAPI.dispatch(getReaderTextChapter({ chapterId: placeRead.payload.chapterId, bookSlug: currentGraphqlBook?.slug }));
          thunkAPI.dispatch(viewChapter({ chapterId: String(placeRead.payload.chapterId) }));
        }
        if (isOpenDropMenu) {
          thunkAPI.dispatch(readerActions.changeOpenDropMenu(false));
        }

        const { currentChapter } = thunkAPI.getState().chapter;

        if (!currentChapter?.chapterId) {
          await Router.push('/404');
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(readerActions.setLoading(false));
      }
    }
  },
);

export const getSettingsFromLocalStorage = createAsyncThunk<void>(
  `${bookReaderSliceName}/getSettingsFromLocalStorage`,
  (_, thunkAPI) => {
    if (!environments.isClient) return;
    const localSettings = localStorage.getItem(textSettingsName);
    if (localSettings) {
      try {
        if (localSettings) {
          thunkAPI.dispatch(readerActions.setSettings(JSON.parse(localSettings)));
          thunkAPI.dispatch(readerActions.setIsDefaultSettings(false));
        }
      } catch (error) {
        thunkAPI.dispatch(readerActions.setSettings(readerDefaultSettings));
      }
    }
  },
);

export const changeReaderChapter = createAsyncThunk<
void, { chapter: Partial<GraphqlEChapter>; page?: string | number; }, { state: RootState }
>(
  `${bookReaderSliceName}/changeReaderChapter`,
  async (data, thunkAPI) => {
    const { currentGraphqlBook } = thunkAPI.getState().book;
    const isBookFormat = isBookFormatBookReaderSelector(thunkAPI.getState());
    const { currentChapter, loading } = thunkAPI.getState().chapter;

    if (data.chapter?.available === false) {
      ym('reachGoal', 'open-buy-book-from-last-free-chapter');
      thunkAPI.dispatch(openBuyBookModal());
      return;
    }

    if ((data.chapter?.chapterId === Number(currentChapter.chapterId)) || loading) return;

    if (window?.scrollTo) {
      window.scrollTo({ top: 0 });
    }

    if (isBookFormat && data.page) {
      await Router.replace(
        `${pagesConfigStore.readerChapter.url}?page=${data.page}`,
        `${pagesConfigStore.readerChapter.as(currentGraphqlBook?.slug, data.chapter.chapterId)}?page=${data.page}`,
        { shallow: true },
      );
    } else {
      await Router.replace(
        pagesConfigStore.readerChapter.url,
        pagesConfigStore.readerChapter.as(currentGraphqlBook?.slug, data.chapter.chapterId),
        { shallow: true },
      );
    }
  },
);

export const hideAddBookInMyBookMessage = createAsyncThunk<
void, { bookId: BookIdType }, { state: RootState }
>(
  `${bookReaderSliceName}/hideAddBookInMyBookMessage`,
  (data) => {
    if (localStorage) {
      const bookIds = localStorage?.getItem(
        bookIdsForAddMyBooksName,
      )?.split(',') ?? [];

      localStorage.setItem(
        bookIdsForAddMyBooksName,
        [...bookIds, data.bookId].join(','),
      );
    }
  },
);

export const getHiddenAddBookInMyBookMessage = createAsyncThunk<
string[], void, { state: RootState }
>(
  `${bookReaderSliceName}/getHiddenAddBookInMyBookMessage`,
  () => {
    if (localStorage) {
      const booksIds = localStorage?.getItem(
        bookIdsForAddMyBooksName,
      )?.split(',') ?? [];
      return booksIds;
    }
    return [];
  },
);

export const changeReaderPage = createAsyncThunk<
void, { page: string | number }, { state: RootState }
>(
  `${bookReaderSliceName}/changeReaderPage`,
  async (data) => {
    try {
      const currentUrl = qs.parseUrl(window.location.href);

      currentUrl.query.page = String(data.page);

      const url = new URL(qs.stringifyUrl(currentUrl));

      const redirectUrl = url.pathname + url.search + url.hash;

      await Router.replace(
        `${Router.pathname}?page=${String(data.page)}`,
        redirectUrl,
        { shallow: true },
      );
    } catch (error) {
      console.error('change reader page error ', error);
    }
  },
);

export const bookReaderNextChapter = createAsyncThunk<
void, void, { state: RootState }
>(
  `${bookReaderSliceName}/bookReaderNextChapter`,
  (data, thunkAPI) => {
    const {
      textSettings,
      openBookFormatPage,
      bookFormatCountPages,
    } = readerSelector(thunkAPI.getState());
    const { currentGraphqlBook } = bookSelector(thunkAPI.getState());
    const { currentChapter } = chapterSelector(thunkAPI.getState());
    const chapters = ebookChaptersSelector(thunkAPI.getState());
    const isFirstChapter = isFirstChapterInReaderSelector(currentChapter.chapterId)(thunkAPI.getState());
    const isSecondChapter = isSecondChapterInReaderSelector(currentChapter.chapterId)(thunkAPI.getState());
    const isLastBookFormatPage = isLastBookFormatPageSelector(thunkAPI.getState());
    const currentChapterIndex = chapters
      .findIndex((chapter) => chapter.chapterId === Number(currentChapter.chapterId));
    const { isBookFormat } = textSettings;
    const nextChapter = chapters[currentChapterIndex + 1] ?? {} as Partial<GraphqlEChapter>;
    const newPage = openBookFormatPage + NEXT_BOOK_FORMAT_PAGE_STEP;

    if (isBookFormat) {
      if (isLastBookFormatPage) {
        if (isFirstChapter && currentGraphqlBook?.ebook?.canBuy) ym('reachGoal', 'reader-move-from-first-to-second-chapter');
        if (isSecondChapter && currentGraphqlBook?.ebook?.canBuy) ym('reachGoal', 'reader-move-from-second-to-third-chapter');
        thunkAPI.dispatch(changeReaderChapter({ chapter: nextChapter, page: 'first' }));
        return;
      }

      if (newPage === bookFormatCountPages - 1) {
        thunkAPI.dispatch(changeReaderPage({ page: 'last' }));
        return;
      }

      thunkAPI.dispatch(changeReaderPage({ page: newPage }));
      return;
    }

    if (isFirstChapter && currentGraphqlBook?.ebook?.canBuy) ym('reachGoal', 'reader-move-from-first-to-second-chapter');
    if (isSecondChapter && currentGraphqlBook?.ebook?.canBuy) ym('reachGoal', 'reader-move-from-second-to-third-chapter');
    thunkAPI.dispatch(changeReaderChapter({ chapter: nextChapter }));
  },
);

export const bookReaderPrevChapter = createAsyncThunk<
void, void, { state: RootState }
>(
  `${bookReaderSliceName}/bookReaderPrevChapter`,
  (data, thunkAPI) => {
    const {
      textSettings,
      openBookFormatPage,
    } = readerSelector(thunkAPI.getState());
    const { currentChapter } = chapterSelector(thunkAPI.getState());
    const chapters = ebookChaptersSelector(thunkAPI.getState());
    const isFirstBookFormatPage = isFirstBookFormatPageSelector(thunkAPI.getState());
    const currentChapterIndex = chapters
      .findIndex((chapter) => chapter.chapterId === Number(currentChapter.chapterId));
    const { isBookFormat } = textSettings;
    const prevChapter = chapters[currentChapterIndex - 1] ?? {} as Partial<GraphqlEChapter>;
    const nextPageCount = openBookFormatPage - NEXT_BOOK_FORMAT_PAGE_STEP;

    if (isBookFormat && isFirstBookFormatPage) {
      thunkAPI.dispatch(changeReaderChapter({ chapter: prevChapter, page: 'last' }));
      return;
    }

    if (isBookFormat && nextPageCount === 0) {
      thunkAPI.dispatch(changeReaderPage({ page: 'first' }));
      return;
    }

    if (isBookFormat) {
      const newPage = openBookFormatPage - NEXT_BOOK_FORMAT_PAGE_STEP;
      thunkAPI.dispatch(changeReaderPage({ page: newPage }));
      return;
    }

    thunkAPI.dispatch(changeReaderChapter({ chapter: prevChapter }));
  },
);

export const getAuthorBooksWithoutCurrentCycleBooks = createAsyncThunk<
void, void, { state: RootState }
>(
  `${bookReaderSliceName}/getAuthorBooksWithoutCurrentCycleBooks`,
  async (_, thunkAPI) => {
    try {
      const { currentGraphqlBook } = bookSelector(thunkAPI.getState());

      if (currentGraphqlBook && 'id' in currentGraphqlBook) {
        const result = await thunkAPI.dispatch(
          getCyclesByAuthorUsername({ authorUsername: currentGraphqlBook.author.username }),
        );

        if (getCyclesByAuthorUsername.fulfilled.match(result)) {
          thunkAPI.dispatch(readerActions.changeOtherAuthorBooksWithoutCurrentCycleBooks(
            result.payload.data.reduce((acc, cycle) => {
              if (
                Number(cycle.id) !== currentGraphqlBook.cycle.id
                || currentGraphqlBook.cycle.name === defaultCycleName
              ) {
                return [...acc, ...cycle.books];
              }

              return acc;
            }, []),
          ));
        }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
